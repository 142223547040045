import React, {useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import "./image_details.css";
import {Textarea} from "../../../common/components/ui/textarea";
import {Button} from "../../../common/components/ui/button";
import {PencilIcon, Sparkles} from "../../../common/icons/icons";
import ImageCard from "./image_card";
import {useGenerativeAPI} from "../../../api/generative_ai_api";
import {useFileAPI} from "../../../api/file_api";
import {uniqueId} from "lodash";
import loading from "../../../common/components/ui/loading.gif";
import AspectRatioCardGroup from "./aspect_ratio_card";

const ImageDetails = ({ form, errors, selectedModels, navigateToModel, aspectRatios }: { form: any, errors: any, selectedModels: any, navigateToModel: (step: any) => void, aspectRatios: any }) => {
    const [starterImage, setStarterImage] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const { enhancePrompt } = useGenerativeAPI();
    const { uploadFile } = useFileAPI();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { setValue, control } = useFormContext();
    const objectId = form.watch("id") ?? uniqueId();

    const handleEditImage = (model: any) => {
        navigateToModel(model);
    }

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const models = []
        if (selectedModels.product) {
            models.push(selectedModels.product.id)
        }
        if (selectedModels.brand) {
            models.push(selectedModels.brand.id)
        }
        if (selectedModels.talent) {
            models.push(selectedModels.talent.id)
        }
        const response = await enhancePrompt(form.getValues("prompt"), models);
        if (response && response.enhanced) {
            setValue("prompt", response.enhanced);
        }
        setIsLoading(false);
    }

    const handleAspectRatioChange = (newAspectRatio: string) => {
        setValue("aspect_ratio", newAspectRatio);
    }

    // TODO bold text if prompt contains generative tag
    // const generativeTagPattern = /#\w+/;


    return (
        <div className="space-y-12">
            <div className="flex w-full gap-14">
                <div className="flex md:flex-row sm:flex-col gap-12">
                    <div className="relative w-[519px]">
                        <Textarea
                            placeholder="Enter image prompt here"
                            className="bg-gray-100 text-black border-none h-[173px] w-full"
                            value={form.watch("prompt")}
                            onChange={(e) => setValue("prompt", e.target.value)}
                        />
                        {isLoading ? (
                            <Button
                                className="w-44 border-none absolute hover:bg-brand-yellow bottom-5 right-5"
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                            >
                                <img className="h-4" src={loading} alt="Loading..." />
                            </Button>
                        ) : (
                            <Button
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                                className="flex gap-2 border-none w-44 hover:bg-black hover:text-white hover:fill-white absolute bottom-5 right-5"
                            >
                                <>
                                    <Sparkles />
                                    Rewrite prompt
                                </>
                            </Button>
                        )}
                    </div>

                    <AspectRatioCardGroup options={aspectRatios} selected={form.watch("aspect_ratio") ?? "SQUARE"}
                                          onSelect={handleAspectRatioChange} size="small" label="Aspect ratio"/>

                    <div className="flex items-center">
                        <Button variant="primary-negative" className="max-w-[177px] max-h-[51px]">Generate image</Button>
                    </div>
                </div>
                {/* <div className="">
                    <p className="subheader">Starter image (optional)</p>
                    <div className="flex gap-3">
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                        />
                        <Button variant="primary-negative" type="button" onClick={browseForStarterImage}>
                            <>Browse<IconArrowRight fill="white" /></>
                        </Button>
                        {starterImage && (
                            <div className="bg-gray-100 flex items-center rounded h-14 gap-3">
                                <img src={URL.createObjectURL(starterImage)} alt={starterImage.name} className="h-full w-auto" />
                                <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]">{starterImage.name}</span>
                                <div onClick={removeStarterImage} className="p-2">
                                    <IconXMark />
                                </div>
                            </div>
                        )}
                    </div>
                </div> */}
            </div>

            <div>
                <p className="subheader">IP vault selections</p>
                <div className="flex flex-col md:flex-row gap-12 flex-wrap">
                    <ImageCard selected imageModel={
                        selectedModels.product
                            ? selectedModels.product
                            : { name: "Product model", group_name: "None selected" }
                    } isCompactView icon={<PencilIcon />} imageAction={() => handleEditImage("product")} />
                    <ImageCard selected imageModel={
                        selectedModels.brand
                            ? selectedModels.brand
                            : { name: "Brand model", group_name: "None selected" }
                    } isCompactView icon={<PencilIcon />} imageAction={() => handleEditImage("brand")} />
                    <ImageCard selected imageModel={
                        selectedModels.talent
                            ? selectedModels.talent
                            : { name: "Talent model", group_name: "None selected" }
                    } isCompactView icon={<PencilIcon />} imageAction={() => handleEditImage("talent")} />
                </div>
            </div>

        </div>
    )

}

export default ImageDetails;

